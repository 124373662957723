import React, { useState } from "react";
import { Avatar, Button, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import { getLanguage } from "../../utils/language";
import "./home.scss";
import { Link } from "react-router-dom";

export default function HomeTopMenu() {
  const [language, setLanguage] = useState(
    getLanguage() === "zh-CN" ? "中文" : "ENGLISH"
  );
  const navigate = useNavigate();
  const gotoApp = () => {
    navigate("/app");
  };
  const localItems = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            i18n.changeLanguage("zh-CN");
            setLanguage("中文");
          }}
        >
          中文
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            i18n.changeLanguage("en-US");
            setLanguage("ENGLISH");
          }}
        >
          ENGLISH
        </div>
      ),
    },
  ];

  return (
    <div className="top-menu container">
      <div className="top-menu-container">
        <div className="top-menu-content">
          <div className="top-menu-left">
            <Avatar
              src={
                <img
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  style={{ objectFit: "contain" }}
                  alt="avatar"
                />
              }
            />
            Tizi
          </div>
          <div className="top-menu-right">
            {/* <div style={{ marginRight: "10px" }}>
              <Link to="/Dapp" style={{ color: "#0f172a",transition: "color .1s ease", textDecoration: "none" }}>Docs</Link>
            </div> */}
            <div style={{ marginRight: "10px" }}>
              <Dropdown
                menu={{
                  items: localItems,
                }}
              >
                <a style={{ cursor: 'pointer', color: "#0f172a",transition: "color .1s ease", textDecoration: "none" }} onClick={(e) => e.preventDefault()}>
                  <Space>
                    {language}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
            <Button onClick={() => gotoApp()}>Launch App</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
