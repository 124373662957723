import { useEffect, useState } from "react";
import { Card, Spin, Popover, Table, Checkbox, Row, Col } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ActivePieChart from "../charts/ActivePieChart";
import ActiveTokenInfoPieChart from "../charts/ActiveTokenInfoPieChart.js";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import TokenAbi from "../../contracts/TDToken.json";
import StragetyAbi from "../../contracts/StrategyAbi.json";
import StatsAbi from "../../contracts/StatsAbi.json";
import contractAddress from "../../contracts/contract-address.json";
import {
  chainRpc,
  chainHasStrategy,
  stragetyCoin,
  chainIdNum,
} from "../../utils/config";
import { formatNumberbyNumeral } from "../../utils/util";

export default function TotalValueDisplay() {
  const { t } = useTranslation();

  const stragetyList = [
    {
      stragetyName: "ExtraBase1",
      data: [],
    },
    {
      stragetyName: "ExtraBase2",
      data: [],
    },
    {
      stragetyName: "TarotBase",
      data: [],
    },
    {
      stragetyName: "ExtraOp1",
      data: [],
    },
    {
      stragetyName: "ExtraOp2",
      data: [],
    },
    {
      stragetyName: "TarotOp",
      data: [],
    },
  ];

  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chainAmountList, setChainAmountList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [stragetyInfoList, setStragetyInfoList] = useState(stragetyList);
  const [lastRebaseTime, setLastRebaseTime] = useState();
  const [tableData, setTableData] = useState();
  const [tokenInfoData, setTokenInfoData] = useState([]);
  const [usdcInfo, setUsdcinfo] = useState();

  const fetchEachChainAmounts = async () => {
    try {
      const tempchartData = [];
      const tempchainList = [];
      const extraBase = {
        name: "ExtrafiBase",
        value: 0,
        chain: 'base',
      };
      const extraOp = {
        name: "ExtrafiOp",
        value: 0,
        chain: 'optimism',
      };
      setLoading(true);
      for (let index = 0; index < chainHasStrategy.length; index++) {
        const element = chainHasStrategy[index];
        const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
        const stragetyContract = new ethers.Contract(
          contractAddress.Stats.address,
          StatsAbi,
          provider
        );
        console.log(stragetyContract);
        console.log(element);
        const vault = await stragetyContract
          .getStrategyTotalValues(
            chainIdNum[element],
            contractAddress.Vault[element]
          )
          .catch((err) => console.log(err));
        const vaultAmount = ethers.utils.formatUnits(vault, 18);
        const singleChartData = {
          name: capitalizeFirstLetter(element) + " balance",
          value: Number(vaultAmount),
          chain: element,
        };
        const singleChain = {
          name: element,
          amount: Number(vaultAmount),
        };
        tempchartData.push(singleChartData);
        for (const key in contractAddress.Strategy[element]) {
          console.log(key);
          // const stragety = await vaultContract.strategyBalance(contractAddress.Strategy[element][key])
          const stragety = await stragetyContract.getStrategyTotalValues(
            chainIdNum[element],
            contractAddress.Strategy[element][key]
          );
          // const stragetyData = await stragetyContract.check()
          // const coinData = stragetyCoin[key]
          // console.log(key, coinData)
          // const pieData = {
          //     name: coinData[0],
          //     value: stragetyData[0]
          // }
          const stragetyAmount = ethers.utils.formatUnits(stragety, 18);
          console.log(key, stragetyAmount);
          const singleChainData = {
            name: key,
            value: Number(stragetyAmount),
            chain: element,
          };
          if (key.indexOf("ExtraBase") === 0) {
            extraBase.value += Number(stragetyAmount);
          } else if (key.indexOf("ExtraOp") === 0) {
            extraOp.value += Number(stragetyAmount)
          } else {
            tempchartData.push(singleChainData);
          }
          singleChain.amount = singleChain.amount + Number(stragetyAmount);
        }
        if (element === 'base') {
          tempchartData.push(extraBase);
        }else if (element === 'optimism') {
          tempchartData.push(extraOp);
        }
        tempchainList.push(singleChain);
      }
      setChartData([...tempchartData]);
      setChainAmountList([...tempchainList]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const capitalizeFirstLetter = (word) => {
    if (typeof word !== "string" || word.length === 0) {
      return "";
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const fetchTotalTD = async () => {
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const tdContract = new ethers.Contract(
      contractAddress.TDToken.base,
      TokenAbi,
      provider
    );
    const rebaseContract = new ethers.Contract(
      contractAddress.TDToken.base,
      TokenAbi,
      provider
    );
    const totalTd = await tdContract.totalSupply();
    const rebaseTime = await rebaseContract.lastRebaseTime();
    setTotalAmount(ethers.utils.formatUnits(totalTd, 18));
    if (parseInt(rebaseTime)) {
      const dateTime = new Date(parseInt(rebaseTime) * 1000);
      const formatTime = dateTime.toLocaleString();
      setLastRebaseTime(formatTime);
    } else {
      setLastRebaseTime("null");
    }
  };

  const fetchExcludeCoin = async () => {
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const statsContract = new ethers.Contract(
      contractAddress.Stats.address,
      StatsAbi,
      provider
    );
    const extraTokenAddress = "0x2dAD3a13ef0C6366220f989157009e501e7938F8";
    const extraBase1 = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.ExtraBase1,
      extraTokenAddress
    );
    const extraBase2 = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.ExtraBase2,
      extraTokenAddress
    );
    const extraBase1Amount = ethers.utils.formatUnits(
      extraBase1.tokenAmount,
      18
    );
    const extraBase2Amount = ethers.utils.formatUnits(
      extraBase2.tokenAmount,
      18
    );
    const extraAmount = formatNumberbyNumeral(
      Number(extraBase1Amount) + Number(extraBase2Amount)
    );
    const data = [
      {
        key: "1",
        coin: "Extra",
        amount: extraAmount,
      },
    ];
    console.log("extraBase1Amount:", data);
    setTableData(data);
  };

  const fetchStragetyInfo = async () => {
    const tokenData = [
      {
        name: "Extra",
        value: undefined,
      },
      {
        name: "Op",
        value: undefined,
      },
      {
        name: "Rdnt",
        value: undefined,
      },
      {
        name: "Arb",
        value: undefined,
      },
    ];
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const statsContract = new ethers.Contract(
      contractAddress.Stats.address,
      StatsAbi,
      provider
    );
    const usdc = await statsContract.calculateTotalChainValues();
    setUsdcinfo({
      name: "usdc",
      value: Number(ethers.utils.formatUnits(usdc, 18)),
    });
    console.log("usdc:", usdc.toString());

    const extraTokenAddress = "0x2dAD3a13ef0C6366220f989157009e501e7938F8";
    const extraBase1 = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.ExtraBase1,
      extraTokenAddress
    );
    const extraBase2 = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.ExtraBase2,
      extraTokenAddress
    );
    const extraBase1Amount = ethers.utils.formatUnits(
      extraBase1.tokenAmount,
      18
    );
    const extraBase2Amount = ethers.utils.formatUnits(
      extraBase2.tokenAmount,
      18
    );
    tokenData[0].value = Number(extraBase1Amount) + Number(extraBase2Amount);

    const opAddress = "0x4200000000000000000000000000000000000042";
    const extraOp1 = await statsContract.getTokenInfoByAddresses(
      "0xa",
      contractAddress.Strategy.optimism.ExtraOp1,
      opAddress
    );
    const extraOp2 = await statsContract.getTokenInfoByAddresses(
      "0xa",
      contractAddress.Strategy.optimism.ExtraOp2,
      opAddress
    );
    const tarotOp = await statsContract.getTokenInfoByAddresses(
      "0xa",
      contractAddress.Strategy.optimism.TarotOp,
      opAddress
    );
    const extraOp1Amount = ethers.utils.formatUnits(extraOp1.tokenAmount, 18);
    const extraOp2Amount = ethers.utils.formatUnits(extraOp2.tokenAmount, 18);
    const tarotOpAmount = ethers.utils.formatUnits(tarotOp.tokenAmount, 18);
    tokenData[1].value =
      Number(extraOp1Amount) + Number(extraOp2Amount) + Number(tarotOpAmount);

    const rdntAddress = "0x3082CC23568eA640225c2467653dB90e9250AaA0";
    const rdntMagpieArb = await statsContract.getTokenInfoByAddresses(
      "0xa4b1",
      contractAddress.Strategy.arbitrum.MagpieArb,
      rdntAddress
    );
    tokenData[2].value = Number(
      ethers.utils.formatUnits(rdntMagpieArb.tokenAmount, 18)
    );

    const arbAddress = "0x912CE59144191C1204E64559FE8253a0e49E6548";
    const arbMagpieArb = await statsContract.getTokenInfoByAddresses(
      "0xa4b1",
      contractAddress.Strategy.arbitrum.MagpieArb,
      arbAddress
    );
    tokenData[3].value = Number(
      ethers.utils.formatUnits(arbMagpieArb.tokenAmount, 18)
    );

    console.log("tokenData:", tokenData);
    setTokenInfoData(tokenData);
  };

  const fetchTokenInfo = async () => {
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const stragetyContract = new ethers.Contract(
      contractAddress.Strategy.base.ExtraBase1,
      StragetyAbi,
      provider
    );
  };

  useEffect(() => {
    try {
      fetchTotalTD();
      fetchEachChainAmounts();
      fetchExcludeCoin();
      fetchStragetyInfo();
      // fetchStragetyInfo()
    } catch (error) {
      console.log(error);
    }
  }, []);

  const ChainBlock = ({ chainList }) => {
    console.log(chainList);
    return chainList.map((entry, index) => (
      <div className="chain-block" key={index}>
        {/* <FrownOutlined/> */}
        <div className="block-text"> {capitalizeFirstLetter(entry.name)} </div>
        <div className="block-text">
          {" "}
          {"$" + formatNumberbyNumeral(Number(entry.amount))}{" "}
        </div>
      </div>
    ));
  };

  const onCheckBoxChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      const data = JSON.parse(JSON.stringify(tokenInfoData));
      data.push(usdcInfo);
      setTokenInfoData(data);
    } else {
      const data = JSON.parse(JSON.stringify(tokenInfoData));
      data.pop();
      setTokenInfoData(data);
    }
  };

  const tableColums = [
    {
      title: "Token",
      dataIndex: "coin",
      key: "coin",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const popoverContent = (
    <div>
      <Table
        columns={tableColums}
        dataSource={tableData}
        pagination={{ position: ["none"] }}
      />
    </div>
  );

  return (
    <div className="main-container">
      <Spin spinning={loading}>
        <div className="chart-container container">
          <div className="chart-title">
            <div className="title">{t("total-value-locked")}</div>
            <div className="title value">{`$ ${formatNumberbyNumeral(
              Number(totalAmount)
            )}`}</div>
          </div>
          <div className="chart-content">
            <Card>
              <div className="card-content-container">
                <h5>Assets (Last Rebase Time: {lastRebaseTime})</h5>
                <div className="card-chain-blocks">
                  <ChainBlock chainList={chainAmountList} />
                </div>
                <Row>
                  <Col xl={12} xs={24} sm={24} md={24} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ActivePieChart data={chartData} />
                      <Popover
                        content={popoverContent}
                        title="Excluded Token"
                        placement="top"
                      >
                        <div>
                          <ExclamationCircleOutlined /> Some small items are
                          excluded
                        </div>
                      </Popover>
                    </div>
                  </Col>
                  <Col xl={12} xs={24} sm={24} md={24} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ActiveTokenInfoPieChart data={tokenInfoData} />
                      <Checkbox onChange={onCheckBoxChange}>
                        Include USDC
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </div>
      </Spin>
    </div>
  );
}
