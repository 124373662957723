// export const mianChianId = '0x2105'
export const mianChianId = '0x2105'
//0x2105 base
//0xaa37dc OP Sepolia
//'0x89', '0xa', '0xa86a',
export const supportedChain = [
     '0x2105', '0xaa37dc', '0xa', '0xa4b1'
]

export const chainHasStrategy = [
     'base', 'optimism', 'arbitrum'
]
//'optimism'

export const chainRpc = {
     // base : 'https://base.llamarpc.com',
     base: 'https://mainnet.base.org/',
     // optimism: 'https://optimism-rpc.publicnode.com'
     optimism: 'https://optimism-mainnet.infura.io/v3/832d078c9eef4a3bb6eb7e562bc16853'
}
export const chainIdNum = {
     base : 8453,
     optimism: 10,
     arbitrum: 42161
}

export const stragetyCoin = {
     ExtraBase1: ['usdc', 'extra'],
     ExtraBase2: ['usdc', 'extra'],
     TarotBase: ['usdc', 'aero'],

     ExtraOp1: ['usdc', 'op'],
     ExtraOp2: ['usdc', 'op'],
     TarotOp: ['usdc', 'op'],
}
