import { Statistic } from "antd"
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import homeImg from "../../images/home.png"

export default function Banner(){
    const { t } = useTranslation()

    const fetchBalance = async () => {
        const initialValue = 1
        const finalValue = 1.002775
        const days = 10
        // 计算日利率
        let dailyInterestRate = Math.pow((finalValue / initialValue), 1/days) - 1;

        // 计算年利率
        let annualInterestRate = Math.pow(1 + dailyInterestRate, 365) - 1;
        console.log('年利率:', annualInterestRate * 100)
        console.log('日利率:', dailyInterestRate * 100)
    }

    useEffect(()=>{
        // fetchBalance()
    }, [])

    return (
        <div className="banner-wrap">
            <div className="banner-center-container container">
                <div className="center-text-container">
                    <div className="center-title">
                        <div className="left-border"></div>
                        <div className="center-content">
                            <div className="main-title">
                                Tizi Money
                            </div>
                            <div style={{color: '#7f8585'}}>
                                {t('multi-text')}
                            </div>
                        </div>
                    </div>
                    <div className="crad-container">
                        <div className="card-content">
                            <div className="stat-content" style={{alignItems: 'center', padding: '0 20px'}}>
                                {/* <Statistic title={"USD+ " + t('apy')} value={24.3} suffix="%"/>
                                <Statistic title={"USD+ " + t('apy')} value={24.3} suffix="%"/>
                                <Statistic title={"USD+ " + t('apy')} value={24.3} suffix="%"/> */}
                                {t('banner-text')}
                            </div>
                        </div>
                    </div>

                </div>
                <img  src={homeImg} className="center-img"/>
            </div>
        </div>
    )
}