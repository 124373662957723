import { hasToken } from '../utils/token'
import { Navigate, Route } from 'react-router-dom'

import React from 'react'

export default function AuthRoute ({ children }) {
  // 判断有无 token
  if (hasToken()) {
    //  有 token, 表示已经登录，可以访问需要登录才能访问的页面（如首页...）
      return children
    } else {
      // 没有 token ,表示未登录，不能访问主页，跳转登录页
      return <Navigate to="/loginPage" replace/>
    }
}

