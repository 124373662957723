import { Menu, Dropdown, Space, Avatar } from "antd";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import {
  DownOutlined,
} from "@ant-design/icons";
import { getAddress } from "../utils/address";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../utils/language";
import { useNavigate, useLocation } from "react-router-dom";

export function TopMenu({ isConnect, disConnectAccount, connectWallet }) {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const location = useLocation()
  const [language, setLanguage] = useState(
    getLanguage() === "zh-CN" ? "中文" : "ENGLISH"
  );
  const [currentMenu, setCurrentMenu] = useState(location.pathname)
  const items = [
    {
      key: "1",
      label: <div onClick={disConnectAccount}>{t("Disconnect")}</div>,
    },
  ];
  const localItems = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            i18n.changeLanguage("zh-CN");
            setLanguage("中文");
          }}
        >
          中文
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            i18n.changeLanguage("en-US");
            setLanguage("ENGLISH");
          }}
        >
          ENGLISH
        </div>
      ),
    },
  ];
  const menuItem = [
    {
      label: t("Mint"),
      key: '/app',
    },
    // {
    //   label: t("Statistics"),
    //   key: '/data',
    // },
    // {
    //   label: t("Wrap"),
    //   key: '/wrap',
    // },
    {
      label: t("Bridge"),
      key: '/bridge',
    },
  ]
  let connectStatus = undefined;
  if (isConnect) {
    connectStatus = (
      <div>
        <Dropdown
          menu={{
            items,
          }}
        >
          <div className="address-info" onClick={(e) => e.preventDefault()}>
            {getAddress()}
          </div>
        </Dropdown>
      </div>
    );
  } else {
    connectStatus = (
      <button className="btn btn-warning" type="button" onClick={connectWallet}>
        {t("connect")}
      </button>
    );
  }
  const gotoHome = () => {
    navigate('/')
  }
  const clickMenu = (e) => {
    setCurrentMenu(e.key)
    if (e.key === '/app') {
      navigate('/app')
    }else if (e.key === '/data') {
      navigate('/data')
    }else if (e.key === '/wrap') {
      navigate('/wrap')
    }else if (e.key === '/bridge') {
      navigate('/bridge')
    }
  }

  return (
    <div className="top-menu-app">
      <div className="top-container-app container">
        <div style={{flex: '1', display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: 10}} className="top-menu-left top-menu-click" onClick={() => gotoHome()}>
            <Avatar
              src={
                <img
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  style={{ objectFit: "contain" }}
                  alt="avatar"
                />
              }
            />
            Tizi
          </div>
          <Menu onClick={clickMenu} selectedKeys={[currentMenu]} mode="horizontal" items={menuItem} style={{minWidth: 0, flex: 'auto'}} />
            {/* <Menu.Item key="home">
              <Link to="/app">{t("Home")}</Link>
            </Menu.Item>
            <Menu.Item key="data">
              <Link to="/data">{t("Statistics")}</Link>
            </Menu.Item>
            <Menu.Item key="data">
              <Link to="/wrap">{t("Wrap")}</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="data" icon={(<MailOutlined/>)}>
              <Link to="/data">数据页</Link>
            </Menu.Item> */}
        </div>
        {/* className="top-btn top-right" */}
        <div className="top-right">
          <div style={{ marginRight: "10px" }}>
            <Dropdown
              menu={{
                items: localItems,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {language}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          {connectStatus}
        </div>
      </div>
    </div>
  );
}
