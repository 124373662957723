import React, { useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link,Navigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { Dapp } from "./components/Dapp"
import Data from './components/Data'
import AuthRoute from './components/AuthRoute'
import Login from './components/login';
import { hasToken } from './utils/token';
import HomePage from './pages/homePage';
import BridgeWTD from './pages/bridge/BridgeWTD';
import {Old} from './pages/old/Old'

const App = () => {
  let content;
  // const [content, setContent] = useState('')
  if (hasToken()) {
    content =  (
      <Menu mode="horizontal">
          <Menu.Item key="home">
            <Link to="/">首页</Link>
          </Menu.Item>
          <Menu.Item key="data">
            <Link to="/data">数据页</Link>
          </Menu.Item>
        </Menu>
    )
  }else{
    content = ''
  }
  return (
    <Router>
      <div>
        {/* {content} */}
        <Routes>
          <Route path="/data" element={<Data />} />
          {/* <Route path="/" element={<Dapp />} /> */}
          <Route path='/app' element={
            <AuthRoute>
              <Dapp />
            </AuthRoute>
          }/>
          <Route path='/' element={
            <AuthRoute>
              <HomePage />
            </AuthRoute>
          }/>
          <Route path='/bridge' element={
            <AuthRoute>
              <BridgeWTD />
            </AuthRoute>
          }/>
          <Route path="/oldApp" element={<Old/>}></Route>
          <Route path="/loginPage" element={<Login/>}></Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;